<template>
  <basic-chat-widget
    ref="chatWidget"
    :target="roomConfiguration"
    :app-id="sidebar ? 61 : 4"
    :title="title"
    :stretch="stretch"
    :sidebar="sidebar"
    :class="{'sidebar-chat' : sidebar}"
    :empty-message="$t('chat.user-chat.empty-message')"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template #room-avatar="{ src }">
      <collective-logo v-if="showAvatar" :logo="src" />
    </template>
  </basic-chat-widget>
</template>

<script>
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import BasicChatWidget from '@/views/apps/chat/widgets/BasicChatWidget.vue';
import RoomType from '@/views/apps/chat/constants/RoomType';

export default {
  name: 'BasicCollectiveChatRoomWidget',
  components: { CollectiveLogo, BasicChatWidget },
  mixins: [WidgetLayoutMixin],
  props: {
    title: {
      type: String,
      default() {
        return this.$t('chat.title');
      },
    },
    sidebar: Boolean,
    showAvatar: Boolean,
    // If true, then the widget will stretch to fill all the available vertical space
    stretch: Boolean,
  },
  computed: {
    roomConfiguration() {
      const collective = this.$store.getters.currentCollective;

      return {
        name: collective.name,
        avatar: `${process.env.VUE_APP_STORAGE_URL}/${collective.logoURL}`,
        room: collective.key,
        roomType: RoomType.Collective,
      };
    },
  },
  methods: {
    scrollToBottom() {
      if (this.$refs.chatWidget) {
        this.$refs.chatWidget.scrollToBottom(0, 0);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-chat {
  height: 100vh !important;
}
</style>
